import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { Utils } from 'utils';
import { PostHttpService } from 'view/cms/features/feature_blog/services';

function InstructorPosts({ authorId }) {


    const [posts, setPosts] = useState(null);


    const { isLoading, error, data, refetch } = useQuery(['posts', authorId], () => { return PostHttpService.loadArtistPosts(authorId) }, {
        onSuccess: (data) => {
            setPosts(data.results)
        },
        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
        refetchInterval: 600 * 10000,
    });

    useEffect(() => {
        refetch()
    }, [authorId])




    return (
        <ul className="lan-rtl mb-3">
            {posts && posts.map((post, index) => (
                <li key={`blog_post_${post.id}`} className="col pb-2">
                    <Link to={`/post/${post.id}/${post.slug}`}>
                        <div className=" lan-text-right">
                                <p className="text-3 mb-0 lan-rtl kfont lan-text-right">{post.title}</p>
                            {/* <div className="">
                                <p className="mb-0 kfont lan-text-right line-height-normal">{Utils.parse(post.short_description, 50)}</p>
                            </div> */}
                        </div>
                    </Link>
                </li>
            )
            )}
        </ul>
    )
}

export default InstructorPosts