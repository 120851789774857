import TranslateController from 'controller/shared/TranslateController';
import React, { useState } from 'react'
import PageLayout from 'view/components/shared/PageLayout';
import { Link } from 'react-router-dom';
import DictionaryByCharacter from '../components/DictionaryByCharacter';
import { BaseHttpService } from 'services/base';
import { useQuery } from 'react-query';
import { HttpService } from 'services/http';
import { Utils } from 'utils';
import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';

function DictionaryViewPage() {

  const [refresh, setRefresh] = useState(0);
  const [query, setQuery] = useState()
  const [selectedEntry, setSelectedEntry] = useState()
  const [showDictionarySuggestionPanel, setShowDictionarySuggestionPanel] = useState()
  const [selectedStartLetter, setSelectedStartLetter] = useState(null)

  const { _t } = TranslateController();

  const [dictionary_entries, setData] = useState(null);


  const { isLoading, error, data, refetch } = useQuery(['dictionary_entry_characters'], () => { return BaseHttpService.searchDictionaryEntries(query) }, {
    onSuccess: (data) => {
      setData(data.results)
    },
    // cacheTime: 60*60*24*30*1000// Cache results for 30 days
    refetchInterval: HttpService.DefaultRefetchInterval,
  });

  useEffect(() => {

    refetch();
    return () => {
    }
  }, [query])

  const alphabet = Array.from(Array(26)).map((_, i) => String.fromCharCode(65 + i));



  return (
    <PageLayout showbreadcrumbs={false} HeaderChild={<HeaderChild query={query} setSearchQuery={setQuery} />}>
      <div className="dictionary-characters-container">
        {alphabet.map((char) => (
          <div key={char} className="character-box" onClick={() => { setSelectedStartLetter(char) }} >
            {char}
          </div>
        ))}
      </div>
      <form className='position-relative' action="" onSubmit={(e) => { e.preventDefault(); setSelectedStartLetter(null); setShowDictionarySuggestionPanel(true); setQuery(query) }}>
        <div className="search-container">
          <div className="dictionary-search-container  input-group mt-2 mb-3 kfont">
            <input className="form-control text-4 dictionary-search-input" value={query} name="q" onChange={(e) => { setQuery(e.target.value); setSelectedStartLetter(null); setShowDictionarySuggestionPanel(true) }} type="search" placeholder="Search..." />

            <button className="btn" type="submit" aria-label="Search">
              <i className="fas fa-search header-nav-top-icon"></i>
            </button>
          </div>
          {showDictionarySuggestionPanel && (
            <div className="dictionary-suggestion-container nice-scroll">
              <ul>
                {isLoading && (
                  <li className='text-center'>
                    <CircularProgress size={15} />
                  </li>
                )}
                {(dictionary_entries) && dictionary_entries.map((item, index) => (
                  <li clas onClick={() => { setShowDictionarySuggestionPanel(false); setSelectedEntry(item) }}>
                    <div className='text-left full-width '>
                      <p className='mb-1 font-weight-bold'>{Utils.getNameByLanguage(1, item)}</p>
                      <p className='mb-0'>{Utils.getDescriptionByLanguage(1, item)}</p>
                    </div>
                    <div className='kfont text-right'>
                      <p className='mb-1 font-weight-bold'>{Utils.getNameByLanguage(3, item)}</p>
                      <p className='mb-0'>{Utils.getDescriptionByLanguage(3, item)}</p>
                    </div>

                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

      </form>

      <div className="container d-flex-column-center mb-3 mt-3">
        <div className="text-center text-1">
          <div>{_t('dictionary guideline')}</div>
        </div>
        {selectedEntry && (
          <div className="text-center mb-3  message-container">
            <div className='text-left'>
              <p className='mb-1 font-weight-bold'>{Utils.getNameByLanguage(1, selectedEntry)}</p>
              <p className='mb-0'>{Utils.getDescriptionByLanguage(1, selectedEntry)}</p>
            </div>
            <div className='kfont text-right '>
              <p className='mb-1 font-weight-bold'>{Utils.getNameByLanguage(3, selectedEntry)}</p>
              <p className='mb-0'>{Utils.getDescriptionByLanguage(3, selectedEntry)}</p>
            </div>

          </div>
        )}


      </div>

      {selectedStartLetter && (
        <DictionaryByCharacter startLetter={selectedStartLetter} />
      )}
    </PageLayout>
  )
}



function HeaderChild({ setSearchQuery }) {
  const { _t } = TranslateController();

  const [count, setCount] = useState(250);

  useEffect(() => {

    _getData()

    return () => {
    }
  }, [])

  const _getData = () => {
    BaseHttpService.loadDictionaryEntries().then((items) => {
      if (items.count) {
        setCount(items.count)
      }
    });
  }

  return (
    <>
      <h1 className="text-color-light pt-3 pb-1 font-weight-bold text-10 text-sm-8">{_t("Dictionary")}</h1>
      <p className='lan-kfont'>{count} {_t('words')}</p>
    </>

  )
}

export default DictionaryViewPage