import React from 'react';
import InstructorDetails from '../course/InstructorDetails';
import { CourseHttpService } from 'services/course';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { CircularProgress } from '@mui/material';
import Header from 'view/components/shared/Header';
import PageHeader from 'view/components/shared/PageHeader';
import Footer from 'view/components/shared/Footer';
import TranslateController from 'controller/shared/TranslateController';

function SlugPage() {
    const { slug } = useParams();

    const { _t } = TranslateController();

    const { isLoading, error, data } = useQuery(['slug', slug], () => {
        return CourseHttpService.findSlug(slug);
    }, {
        onSuccess: (res) => {
            // Perform any action on success if needed
        }
    });

    if (isLoading) {
        return (
            <div className="body">
            <Header />
            <div role="main" className="main">
                <PageHeader child={<h2>{_t("Loading")}</h2>} showbreadcrumbs={false} />

                <div id='main'>
                    <div className='custom-page-content p-relative z-index-2'>
                        <div className="text-center container custom-padding-top-1 custom-padding-bottom-1 px-lg-5 bg-light">
                        <CircularProgress size={35} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        );
    }

    if (error) {
        return (
            <div className="body">
                <Header />
                <div role="main" className="main">
                    <PageHeader child={<p>{_t("Error")}</p>} showbreadcrumbs={false} />

                    <div id='main'>
                        <div className='custom-page-content p-relative z-index-2'>
                            <div className="container custom-padding-top-1 custom-padding-bottom-1 px-lg-5 bg-light">
                                <p>{_t("Error loading data")}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

    if (data && data.type === 'instructor') {
        return <InstructorDetails artist={data.data} />;
    }

    return (
        <div className="body">
            <Header />
            <div role="main" className="main">
                <PageHeader child={<h2>{_t("404 not found")}</h2>} showbreadcrumbs={false} />

                <div id='main'>
                    <div className='custom-page-content p-relative z-index-2'>
                        <div className="container custom-padding-top-1 custom-padding-bottom-1 px-lg-5 bg-light">
                            <p>{_t("The resource that your are looking for can't be fount")}</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default SlugPage;
