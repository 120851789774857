import { Navigate, Route, Routes } from "react-router-dom";
import ReviewPage from "view/cms/pages/course/ReviewPage";
import { HttpService } from "services/http";
import CourseDetailsPage from "view/cms/pages/course/CourseDetails";
import CoursePage from "view/cms/pages/course/CoursePage";
import InstructorPage from "view/cms/pages/course/InstructorPage";
import LevelPage from "view/cms/pages/course/level/LevelPage";
import CmsLogin from "view/cms/pages/shared/CmsLogin";
import ContentView from "view/pages/course/ContentView";
import CourseDetails from "view/pages/course/CourseDetails";
import Courses from "view/pages/course/Courses";
import InstructorDetails from "view/pages/course/InstructorDetails";
import Home from "view/pages/shared/Home";
import Watch from "view/pages/shared/Watch";
import PrivateRoute from "./PrivateRoute";
import UserDashboard from "view/pages/dashboard/UserDashboard";
import Dashboard from "view/cms/pages/shared/Dashboard";
import QuizView from "view/pages/course/QuizView";
import ContentDetailsPage from "view/cms/pages/course/ContentDetailsPage";
import Profile from "view/components/user/Profile";
import FaqPage from "features/feature_faq/pages/FaqPage";
import SearchPage from "features/feature_search/pages/SearchPage";
import AboutPage from "view/pages/shared/About";
import Blog from "view/features/feature_blog/pages/Blog";
import CourseLectures from "view/pages/dashboard/CourseLectures";
import LectureDetails from "view/pages/dashboard/LectureDetails";
import CategoryPage from "view/cms/features/feature_blog/pages/CategoryPage";
import PostsPage from "view/cms/features/feature_blog/pages/PostsPage";
import Settings from "view/cms/pages/shared/Settings";
import Post from "view/features/feature_blog/pages/Post";
import LanguagesPage from "view/cms/features/feature_settings/pages/LanguagesPage";
import TranslationsPage from "view/cms/features/feature_settings/pages/TranslationsPage";
import FaqsPage from "view/cms/features/feature_settings/pages/FaqsPage";
import GeneralSettings from "view/cms/features/feature_settings/pages/General";
import Users from "view/cms/features/feature_user/pages/Users";
import AuthorsPage from "view/cms/features/feature_blog/pages/AuthorsPage";
import AuthorDetails from "view/features/feature_blog/pages/AuthorDetails";
import DictionaryPage from "view/cms/features/feature_settings/pages/DictionaryPage";
import DictionaryViewPage from "view/features/feature_home/pages/DictionaryViewPage";
import InstructorsPage from "view/pages/course/InstructorsPage";
import AboutItemsPage from "view/cms/features/feature_settings/pages/AboutItemsPage";
import BlogCategory from "view/features/feature_blog/pages/BlogCategory";
import VerifyPage from "view/pages/shared/Verify";
import ResetPasswordPage from "view/pages/shared/Reset";
import PodcastPage from "view/cms/pages/course/PodcastPage";
import PodcastDetailsPage from "view/cms/pages/course/PodcastDetailsPage";
import Podcasts from "view/pages/course/Podcasts";
import Podcast from "view/pages/course/Podcast";
import Library from "view/pages/shared/Library";
import Books from "view/features/feature_blog/pages/Books";
import Publications from "view/features/feature_blog/pages/publications";
import Notation from "view/features/feature_notation/pages/Notation";
import NotationPage from "view/cms/features/feature_notation/pages/NotationPage";
import NotationDetailsPage from "view/features/feature_notation/pages/NotationDetailsPage";
import Notations from "view/pages/course/Notations";
import ScalesPage from "view/cms/features/feature_notation/pages/ScalesPage";
import TimeSignaturesPage from "view/cms/features/feature_notation/pages/TimeSignaturesPage";
import ContactPage from "view/pages/shared/Contact";
import AccountDeletionPage from "view/features/feature_home/pages/AccountDeletionPage";
import UserDetailsPage from "view/cms/features/feature_user/pages/UserDetailsPage";
import MediaPage from "view/cms/pages/shared/MediaPage";
import BlogByKeyword from "view/features/feature_blog/pages/BlogByKeyword";
import SupportMessagesPage from "view/cms/pages/support_messages/SupportMessagesPage";
import UserSupportMessages from "view/pages/dashboard/UserSupportMessages";
import NotationArtists from "view/pages/course/NotationArtists";
import TranscribersPage from "view/pages/course/TranscribersPage";
import PodcastWritersPage from "view/pages/course/PodcastWritersPage";
import NarratorsPage from "view/pages/course/NarratorsPage";
import AuthorsPageGrid from "view/pages/course/AuthorsPageGrid";
import SlugPage from "view/pages/shared/Slug";

const MainRoutes = () => {
  const localStorageToken = HttpService.getToken()
  const localStorageInfo = HttpService.getUser()

  const path = window.location.pathname;

  if(!localStorageToken && path.includes("/cms"))
  {
    if (window.location.pathname !== '/cms/login/') {
      window.location.href = '/cms/login/';
    }
  }


  const _adminRoutes = () => {
    return (
      <>
        <Route path="/cms/dashboard" element={<Dashboard />} />
        <Route path="/cms/levels" element={<LevelPage />} />
        <Route path="/cms/course" element={<CoursePage />} />
        <Route path="/cms/course/:id" element={<CourseDetailsPage />} />
        <Route path="/cms/content/:id" element={<ContentDetailsPage />} />
        <Route path="/cms/instructor" element={<InstructorPage />} />
        <Route path="/cms/author" element={<AuthorsPage />} />
        <Route path="/cms/review" element={<ReviewPage />} />
        <Route path="/cms/settings" element={<Settings />} />
        <Route path="/cms/settings/faq" element={<FaqsPage />} />
        <Route path="/cms/settings/general" element={<GeneralSettings />} />
        <Route path="/cms/users" element={<Users />} />
        <Route path="/cms/user/:id" element={<UserDetailsPage />} />
        <Route path="/cms/about" element={<AboutItemsPage />} />
        <Route path="/cms/media" element={<MediaPage />} />
        
        <Route path="/cms/category" element={<CategoryPage />} />
        <Route path="/cms/posts" element={<PostsPage />} />
        
        <Route path="/cms/languages" element={<LanguagesPage />} />
        <Route path="/cms/translations" element={<TranslationsPage />} />
        <Route path="/cms/dictionary" element={<DictionaryPage />} />

        <Route path="/cms/podcast" element={<PodcastPage />} />
        <Route path="/cms/podcast/:id" element={<PodcastDetailsPage />} />


        <Route path="/cms/notation" element={<NotationPage />} />
        <Route path="/cms/notation/:id" element={<NotationDetailsPage />} />
        <Route path="/cms/notation/add" element={<NotationDetailsPage />} />

        <Route path="/cms/notation/scale" element={<ScalesPage />} />
        <Route path="/cms/notation/time-signature" element={<TimeSignaturesPage />} />
        
        
        <Route path="/cms/support" element={<SupportMessagesPage />} />

        {/* Add more admin routes here */}
      </>
    );
  };

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/:slug" element={<SlugPage />} />
      <Route path="/" element={<Home />} />
      <Route path="/watch" element={<Watch />} />
      <Route path="/courses" element={<Courses />} />
      <Route path="/course" element={<Courses />} />
      <Route path="/course/:id" element={<CourseDetails />} />
      <Route path="/course/:id/home/welcome" element={<CourseDetails />} />
      <Route path="/instructors/" element={<InstructorsPage />} />
      <Route path="/instructor/:id" element={<InstructorDetails />} />
      <Route path="/us/:slug" element={<InstructorDetails />} />
      <Route path="/transcribers/" element={<TranscribersPage />} />
      <Route path="/authors/" element={<AuthorsPageGrid />} />
      <Route path="/podcast-writers/" element={<PodcastWritersPage />} />
      <Route path="/narrators/" element={<NarratorsPage />} />

      <Route path="/faq" element={<FaqPage />} />
      <Route path="/dictionary" element={<DictionaryViewPage />} />
      <Route path="/explore" element={<SearchPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/validate" element={<VerifyPage />} />
      <Route path="/articles" element={<Blog />} />
      <Route path="/articles/:category_id" element={<BlogCategory />} />
      <Route path="/keyword/" element={<BlogByKeyword />} />
      <Route path="/keyword/:keyword" element={<BlogByKeyword />} />
      <Route path="/post/:id" element={<Post />} />
      <Route path="/post/:id/:slug" element={<Post />} />
      <Route path="/author/:id" element={<AuthorDetails />} />
      <Route path="/reset" element={<ResetPasswordPage />} />

      <Route path="/podcasts" element={<Podcasts />} />
      <Route path="/podcast/:id" element={<Podcast />} />
      <Route path="/podcast/:id/:episodeIndex/:title" element={<Podcast />} />
      <Route path="/podcast/:id/:episodeIndex" element={<Podcast />} />
      
      <Route path="/notations" element={<NotationArtists />} />
      <Route path="/notations/:artist_id/:title" element={<Notations />} />
      
      <Route path="/notation/:id" element={<Notation />} />

      <Route path="/library" element={<Library />} />
      <Route path="/books/" element={<Books />} />
      <Route path="/publications/" element={<Publications />} />
      <Route path="/delete-account-confirm/:uidb64/:token" element={<AccountDeletionPage />} />


      {/* Admin Routes */}
      <Route path="/cms" element={localStorageInfo?.role === "admin" ? <Dashboard /> : <CmsLogin />} />

      {/* Private Routes */}
      <Route path="/home" element={<PrivateRoute><UserDashboard /></PrivateRoute>} />
      <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
      <Route path="/support" element={<PrivateRoute><UserSupportMessages /></PrivateRoute>} />
      <Route path="/course/:id/lectures" element={<PrivateRoute><CourseLectures /></PrivateRoute>} />
      <Route path="/course/:course_id/lecture/:lecture_id" element={<PrivateRoute><LectureDetails /></PrivateRoute>} />
      <Route path="/course/:course/lesson/:lesson/content/:content/" element={<PrivateRoute><ContentView /></PrivateRoute>}/>
      <Route path="/course/:course/lesson/:lesson/quiz/:quiz/" element={<PrivateRoute><QuizView /></PrivateRoute>}/>
       
      
      {localStorageToken && (localStorageInfo?.role === "admin" || localStorageInfo?.role === "editor" ) ? (
        _adminRoutes()
      ) : (
        <></>
      )}
      <Route path="/cms/login" element={<CmsLogin />} />
      <Route path="/login" element={<CmsLogin />} />
    </Routes>
  );
};

export default MainRoutes;