import React, { useEffect, useRef, useState } from 'react'
import { BaseHttpService } from '../../../../../services/base';
import CustomModal from '../../../../components/modals/CustomModal';

export default function Gallery(props) {

    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [error, setError] = useState(false);
    const [gallery_images, setGalleryImages] = useState(false);
    const [count, setGalleryCount] = useState(false);
    const [gallery_pages, setGalleryPages] = useState(false);
    const [gallery_modal_is_open, setGalleryModalIsOpen] = useState(false);
    const [uploaded_image, setUploadedImage] = useState(false);
    const [file, setFile] = useState(false);
    const [page, setPage] = useState(1);
    const [delete_loading, setDeleteLoading] = useState(null);
    const [selectedGalleryItem, setSelectedGalleryItem] = useState(null);
    
    const inputFileRef = useRef(null)

    useEffect(() => {

        if (props.isOpen) {
            setGalleryModalIsOpen(props.isOpen)
        }
        _getData(1)

    }, []);

    const _getData = (page) => {
        setLoading(true)
        let type = props.type || "";
        BaseHttpService.loadGalleries(page,type).then(data => {
            if (data && data.count) {
                setGalleryCount(data.count)
                var page_count = data.count % 15 === 0 ? parseInt(data.count / 15) : parseInt(data.count / 15) + 1;
                var pages_array = range(1, page_count);
                setGalleryPages(pages_array);
            }
            if (data.results)
                setGalleryImages(data.results)
            setLoading(false)
        })
    }

    function range(start, end) {
        return Array.apply(0, Array(end))
            .map((element, index) => index + start);
    }

    const handleFileChange = (e) => {
        if (e.target.files){
            var file = e.target.files[0]
            if(file){
                setFile(file)
                var name = file.name.split('.').slice(0, -1).join('.')
                setTitle(name)
            }
        }

    }
    const handleChange = (e) => {
        setTitle(e.target.value)

    }

    const uploadToGallery = () => {
        setError(false)
        if (!title) {
            setError("Please Enter Name")
            return;
        }
        if (!file) {
            setError("Please Select an image")
            return;
        }

        let type = props.type || "";

        setLoading(true)
        BaseHttpService.SaveGallery(title,type, file).then(data => {
            setLoading(false)
            if (data) {
                setUploadedImage(data)
                setTitle("")
            }
        })

    }
    const closeGallery = () => {
        setGalleryModalIsOpen(false)
        props.closeGallery()
    }
    const loadPage = (e, page) => {
        e.preventDefault()
        _getData(page)
    }

    const setSelectedImageGallery = (gallery_item) => {
        setSelectedGalleryItem(gallery_item)
        
    }

    const selectImage = (gallery_item) => {
        props.setSelectedGalleryItem(gallery_item)
        closeGallery()
    }

    const deleteGalleryItem = (e, gallery_id) => {
        e.preventDefault()
        setDeleteLoading(gallery_id)

        
        BaseHttpService.deleteItem(`/postapi/gallery/${gallery_id}`).then(() => {
            _getData(page)
        })

        return
    }

    const _closeModal = () => {
        if (props.closeGallery) {
            props.closeGallery()
        }

        setGalleryModalIsOpen(false)
    }


    return (


        <CustomModal xl className="" key={"galmod" + gallery_modal_is_open}
            setOpen={(value) => _closeModal()}
            open={gallery_modal_is_open} >

            <div className="modal-content">

                <div className="tabs">
                    <ul className="nav nav-tabs nav-justified flex-column flex-md-row"  role="tablist">
                        <li className="nav-item kfont ">
                            <a href={`#images${props?.type}`} data-bs-toggle="tab" aria-selected="true" role="tab" className="nav-link active">Select {props?.type=='document' ? 'Document' : 'Image'}</a></li>
                        <li className="nav-item kfont ">
                            <a href={`#upload${props?.type}`} className="nav-link" data-bs-toggle="tab" aria-selected="false"  role="tab">Upload</a></li>
                    </ul>
                    <div className="tab-content ">
                        <div className="tab-pane active" id={`images${props?.type}`} role='tabpanel'>
                            <div className="row">
                                <div className="col-md-8">
                                    {loading && (<>
                                    <div className="gallery-loading">
                                        Please Wait...
                                    </div>

                                    </>)}
                                    <div className="gallery-tab mb-3">
                                        {gallery_images && gallery_images.map(item => (
                                            <div key={item.id} className={`gallery-item ${delete_loading === item.id ? "gallery_delete_loading" : ""}`}>
                                                <img src={props?.type=="document" ? "/assets/images/svg/pdf.svg" : item.image} alt={item.title} onClick={() => { setSelectedImageGallery(item) }} />
                                                <small>{item.document && item.title}</small>
                                                <i className="fa fa-trash btn-gallery-delete" onClick={(e) => { if (window.confirm('Are you sure you wish to delete this item?')) deleteGalleryItem(e, item.id) }}></i>
                                            </div>

                                        ))}
                                        <div className="clear"></div>
                                    </div>

                                    <ul className="pagination pagination-sm m-0 float-right">
                                        {gallery_pages && gallery_pages.map((page_number) => (
                                            <li key={page_number} className="page-item">
                                                <a className={page_number === page ? "page-link active" : "page-link"}
                                                    href="#loadpage" onClick={(e) => { loadPage(e, page_number) }}>{page_number}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="col-md-4 p-2 bg-eee">
                                        {selectedGalleryItem && (
                                            <>
                                                <a href={`${selectedGalleryItem.image}`} target='_blank'><img className='selected-gallery-item-image' src={props?.type=="document" ? "/assets/images/svg/pdf.svg" : `${selectedGalleryItem.image}`} /></a>
                                                <p>{selectedGalleryItem.title}</p>
                                                <p>{selectedGalleryItem.type}</p>

                                                <button className='btn btn-primary pull-right mr-2' onClick={()=>{ selectImage(selectedGalleryItem) }} >Select</button>
                                            </>
                                        )}
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane " id={`upload${props?.type}`}>
                            <div className="gallery-upload">
                                <input type="text" value={title} placeholder="Name" onChange={handleChange} className="form-control max-w-300" />
                                <input type="file" ref={inputFileRef} onChange={handleFileChange} className="form-control max-w-300" />
                                <input type="button" className="btn btn-default" value="Upload" onClick={uploadToGallery} />
                            </div>
                            {error &&
                                <div className="error">
                                    {error}
                                </div>
                            }
                            {loading && (<>
                                <div className="gallery-loading">
                                    Please Wait...
                                </div>

                            </>)}

                            {uploaded_image && (
                                <img className="gallery-uploaded " onClick={() => { setSelectedImageGallery(uploaded_image); selectImage(uploaded_image); }} src={uploaded_image.image ? uploaded_image.image : "/assets/images/svg/pdf.svg"} alt="uploaded" />
                            )}

                        </div>

                    </div>
                </div>

            </div>
        </CustomModal>

    )
}
