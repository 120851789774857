import React, { useContext, useEffect, useState } from 'react'
import { Utils, translate } from '../../../utils'
import PageLayout from 'view/components/shared/PageLayout'
import TranslateController from 'controller/shared/TranslateController'
import NotationsArtistGrid from 'view/components/course/components/NotationsArtistGrid'
import NotationsGrid from 'view/components/course/components/NotationsGrid'
import CustomModal from 'view/components/modals/CustomModal'
import SelectLevel from 'view/cms/components/forms/course/selects/SelectLevel'
import SelectInstructorList from 'view/cms/components/lists/SelectInstructorList'
import SelectScale from 'view/cms/features/feature_notation/components/SelectScale'
import SelectTimeSignature from 'view/cms/features/feature_notation/components/SelectTimeSignature'
import { CourseHttpService } from 'services/course'
import { useQuery } from 'react-query'

function NotationArtists() {

    const [query, setQuery] = useState("")
    const { _t } = TranslateController();
    const [selectedStartLetter, setSelectedStartLetter] = useState(null)
    const [searchModalIsOpen, setSearchModalIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState()
    const [refresh, setRefresh] = useState(0)
    const [showArtists, setShowArtists] = useState(true)
    
    const alphabet = Array.from(Array(26)).map((_, i) => String.fromCharCode(65 + i));

    useEffect(() => {
        // Utils.initiateTheme()
    }, [])

    const [advancedSearch, setAdvancedSearch] = useState({
        name: "",
        time_signature: "",
        scale: "",
        level: "",
        artists: "",
        writers: "",
    });

    const buildQueryString = () => {
        let queryString = `?size=60`;
        for (const key in advancedSearch) {
            if (advancedSearch[key]) {
                queryString += `&${key}=${advancedSearch[key]}`;
                if(key=='name'){
                    setQuery(advancedSearch[key])
                }
            }
        }

        // if(selectedStartLetter){
        //     queryString += `&start_letter=${selectedStartLetter}`;
        // }
        return queryString;
    };

    const handleAdvancedSearchChange = (e) => {
        const { name, value } = e.target;
        setAdvancedSearch(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const handleAdvancedSearchSubmit = () => {
        const queryString = buildQueryString();
        setSearchQuery(queryString);
        setSearchModalIsOpen(false);
        setShowArtists(false)
    };

    const handleStartLetterClick = (char) => {
        var queryString = `?size=60`;
        queryString += `&start_letter=${char}`;
        setQuery("")
        setSearchQuery(queryString);
        setShowArtists(false)

    };

    


    return (
        <PageLayout showbreadcrumbs={false} HeaderChild={<HeaderChild />}>
            <div className="mb-5">
                <div className="dictionary-characters-container">
                    {alphabet.map((char) => (
                        <div key={char} className="character-box" onClick={() => { setSelectedStartLetter(char); handleStartLetterClick(char); }} >
                            {char}
                        </div>
                    ))}
                </div>
                <form action="" className='text-center' onSubmit={(e) => { e.preventDefault(); setSearchQuery(`?name=${query}`) }}>
                    <div className="search-container">
                        <div className="dictionary-search-container  input-group mt-2 mb-3 kfont">
                            <input className="form-control text-4 dictionary-search-input" value={query} name="q" onChange={(e) => {          
                                setShowArtists(true)
                                setSelectedStartLetter("");  setQuery(e.target.value);
                                setSearchQuery(`?name=${e.target.value}`);
                                 }} type="search" placeholder="Search..." />

                            <button className="btn" type="submit" aria-label="Search">
                                <i className="fas fa-search header-nav-top-icon"></i>
                            </button>
                            <button className="btn no-border" type="button" aria-label="Advanced Search" onClick={() => setSearchModalIsOpen(true)}>
                                <i className="fas fa-sliders-h header-nav-top-icon "></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {showArtists && (<NotationsArtistGrid  query={query} />) }
            {searchQuery && (<NotationsGrid start_letter={selectedStartLetter} loadMore={false} query={searchQuery} />) }

            {searchModalIsOpen && (
                <CustomModal dark medium key={searchModalIsOpen} setOpen={(value) => setSearchModalIsOpen(value)} open={searchModalIsOpen}>
                    <form onSubmit={handleAdvancedSearchSubmit} className='lan-rtl'>
                        <h2 className='text-light'>{_t("Advanced Search")}</h2>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        placeholder={_t('Song Name')}
                                        name="name"
                                        value={advancedSearch.name}
                                        onChange={handleAdvancedSearchChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <SelectLevel
                                        id="level"
                                        placeholder={_t('Level')}
                                        name="level"
                                        width={"100%"}
                                        value={advancedSearch.level}
                                        callback={(value, name) => handleAdvancedSearchChange({ target: { name: 'level', value } })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <SelectScale
                                        id="scale"
                                        name="scale"
                                        placeholder={_t('Scale')}
                                        width={"100%"}
                                        value={advancedSearch.scale}
                                        callback={(value, name) => handleAdvancedSearchChange({ target: { name: 'scale', value } })}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <SelectTimeSignature
                                        id="time_signature"
                                        name="time_signature"
                                        placeholder={_t('Time Signature')}
                                        isClearable={true}
                                        width={"100%"}
                                        value={advancedSearch.time_signature}
                                        callback={(value, name) => handleAdvancedSearchChange({ target: { name: 'time_signature', value } })}
                                    />
                                </div>
                            </div>

                        </div>

                        <button type="submit" className="btn btn-primary mr-2 lan-ml-2 ">{_t("Search")}</button>
                        <button type="button" className="btn btn-secondary" onClick={() => setSearchModalIsOpen(false)}>{_t("Close")}</button>
                    </form>
                </CustomModal>
            )}
            
        </PageLayout>


    )
}



function HeaderChild({ setQuery }) {
    const { _t } = TranslateController();

    const [count, setCount] = useState(100)

    const { isLoading, error, data, refetch } = useQuery(
        ['notations_count'],
        () => {
            return CourseHttpService.loadNotations();
        },
        {
            onSuccess: (data) => {
                if(data.count){
                    setCount(data.count);
                }
            },
            refetchInterval: 600 * 100000,
        }
    );


    return (
        <>
            <h1 className="text-color-light pt-3 pb-2 font-weight-bold text-10 text-sm-8">{_t("Notations")}</h1>
            <div className='kfont'>{count} {_t("notes")}</div>

        </>
    );
}

export default NotationArtists