import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translate, Utils } from '../../../../utils';
import TranslateController from 'controller/shared/TranslateController';

function InstructorSummary({ instructor }) {

    const { setLanguage, translations } = useContext(LanguageContext);
    const {_t} = TranslateController()

    return (
        <section className="course-summary section section-concept section-no-border section-angled section-angled-reverse pt-5 m-0" >
            {/* <div className="section-angled-layer-bottom section-angled-layer-increase-angle bg-light"></div> */}
            <div className="container lan-rtl ">
                <div className="row pt-3 pb-lg-0 pb-xl-0">
                    <div className="col-lg-6 mb-lg-0 text-left">
                        {/* <ul className="breadcrumb font-weight-semibold text-4 negative-ls-1 mb-3">
                            <li><a href="index.html">{_t("Browse")}</a></li>
                            <li className="text-color-primary"><a href="/instructors">{_t("Instructors")}</a></li>
                            <li className="active text-color-primary">{_t("Instructor")}</li>
                        </ul> */}
                        <h1 className="font-weight-bold text-10 text-xl-12 text-sm-8 line-height-2 mb-3 lan-kfont d-block lan-text-right">{instructor && Utils.getDefaultName(instructor)}</h1>
                        <p className="font-weight-light opacity-8 pb-2 mb-4 text-justify text-light lan-kfont">{instructor && Utils.parseShortDescription(instructor)}</p>
                        {/* <Link to={`/instructor/${instructor?.id}/#main`} data-hash data-hash-offset="100" className="btn btn-gradient-primary text-light opacity-5 font-weight-semi-bold px-4 btn-py-2 text-3">View details <i className="fas fa-arrow-down ml-1"></i></Link> */}

                    </div>
                    <div className="col-lg-6 pt-4 pt-sm-0 mb-5 mb-lg-0 ">
                        <img src={Utils.getImage(instructor)} className="instructor-image mx-auto d-block lan-kfont" alt={instructor && Utils.getDefaultName(instructor)} />
                    </div>

                </div>
            </div>
        </section>
    )
}

export default InstructorSummary