import React from 'react'
import { Link } from 'react-router-dom'
import { Utils } from '../../../../utils'

function InstructorItem({ instructor }) {
    return (
        <div className="instructor-item" key={`instructor ${instructor.id}`}>
            
            <div className="instructor-info">
                <Link to={instructor.slug ? `/${instructor.slug}` : `/instructor/${instructor.id}`} className="">
                    <h3 className='mb-2 text-5'>{Utils.getDefaultName(instructor)}</h3>
                </Link>
                <div>{Utils.parseShortDescription(instructor)}</div>
            </div>
            <div className="ins-img">
                <Link to={instructor.slug ? `/${instructor.slug}` : `/instructor/${instructor.id}`} className="">
                    <img src={Utils.getImage(instructor)} alt={Utils.getDefaultName(instructor)} />
                </Link>
            </div>
        </div>
    )
}

export default InstructorItem