import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { CourseHttpService } from 'services/course';
import { Utils } from 'utils';

function NotationsGridHome({ query, size = 4 }) {
    const [notations, setNotations] = useState(null);

    const { isLoading, error, data, refetch } = useQuery('notations_home', () => {
        return CourseHttpService.loadNotations("",6);
    }, {
        onSuccess: (data) => {
            setNotations(data.results);
        },
        refetchInterval: 600 * 100000,
    });

    return (
        <div className='mt-1 container '>
            <div className="row portfolio-list sort-destination lan-rtl" >
                {notations?.map((notation, index) => (
                    <div key={notation.id + "ngi"} className="col-6 col-md-2 " >
                        <div className="portfolio-item mb-1">
                            <Link to={`/notation/${notation.id}`}>
                                <span className="thumb-info thumb-info-lighten border-radius-0 kfont lan-rtl">
                                    <span className="thumb-info-wrapper border-radius-0">
                                        <img src={Utils.getNotationImage(notation)} className="img-fluid border-radius-0 notation-grid-img" alt="" />
                                        <span className="thumb-info-title">
                                            <span className="thumb-info-inner font-weight-light">{Utils.getDefaultName(notation)}</span>
                                            <span className="thumb-info-type hide-on-small">{notation?.level && Utils.getDefaultName(notation?.level)}</span>
                                        </span>
                                        <span className="thumb-info-action">
                                            <span className="thumb-info-action-icon bg-dark opacity-8"><i className="fas fa-play"></i></span>
                                        </span>
                                    </span>
                                </span>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default NotationsGridHome;
