import { PauseCircleOutline, PlayCircle, PlayCircleOutline, RefreshOutlined } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';

const MusicNotationImage = ({ notePages, audioSrc }) => {
  const imgRef = useRef(null);
  const audioRef = useRef(null);
  const containerRef = useRef(null);

  const [currentPageId, setCurrentPageId] = useState(notePages[0]?.id);
  const [currentSquareId, setCurrentSquareId] = useState(null);
  const [clickedSquareId, setClickedSquareId] = useState(null);
  const [hoveredSquareId, setHoveredSquareId] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const originalWidth = 800;
  const originalHeight = 1131;

  useEffect(() => {
    const handleResize = () => {
      const img = imgRef.current;
      const container = containerRef.current;
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;

      const scale = Math.min(containerWidth / originalWidth, containerHeight / originalHeight);

      img.style.width = `${originalWidth * scale}px`;
      img.style.height = `${originalHeight * scale}px`;
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call to set size based on container

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [currentPageId]);

  const syncHighlight = () => {
    const currentTime = audioRef.current.currentTime * 1000; // Convert to milliseconds

    let newCurrentPageId = currentPageId;
    for (let i = 0; i < notePages.length; i++) {
      if (currentTime >= notePages[i].time && (i === notePages.length - 1 || currentTime < notePages[i + 1].time)) {
        newCurrentPageId = notePages[i].id;
        break;
      }
    }

    if (newCurrentPageId !== currentPageId) {
      setCurrentPageId(newCurrentPageId);
    }

    const currentPage = notePages.find(page => page.id === newCurrentPageId);
    const currentSquare = currentPage?.squares.find((square, index) => {
      const nextSquare = currentPage?.squares[index + 1];
      if (!nextSquare) return currentTime >= square.time;
      return currentTime >= square.time && currentTime < nextSquare.time;
    });

    if (currentSquare) {
      setCurrentSquareId((prevSquareId) => {
        if (prevSquareId !== null && prevSquareId !== currentSquare.id) {
          setClickedSquareId(null);
        }

        scrollToSquare(currentSquare);

        return currentSquare.id;
      });
    } else {
      setCurrentSquareId(null);
    }
  };

  const handleClick = (square) => {
    setClickedSquareId(square.id);
    audioRef.current.currentTime = square.time / 1000; // Seek to the square time in seconds
    audioRef.current.play();
    setIsPlaying(true);
  };

  const handleMouseEnter = (square) => {
    setHoveredSquareId(square.id);
  };

  const handleMouseLeave = () => {
    setHoveredSquareId(null);
  };

  const handlePlayPause = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
      syncHighlight();
    } else {
      setIsPlaying(false);
      audioRef.current.pause();
    }
  };

  const handleStop = () => {
    const audio = audioRef.current;
    audio.pause();
    setIsPlaying(false);
    audio.currentTime = 0;
    setCurrentPageId(notePages[0]?.id);
    setCurrentSquareId(null);
    setClickedSquareId(null);
    setHoveredSquareId(null);
  };

  const scrollToSquare = (square) => {
    const isBigScreen = window.matchMedia("(min-width: 768px)").matches;

    if (isBigScreen) {
      const container = containerRef.current;
      const containerTop = container.getBoundingClientRect().top;
      const imgTop = imgRef.current.getBoundingClientRect().top;
      const squareTop = square.top * (imgRef.current.height / originalHeight) + imgTop - containerTop - 20;
      window.scrollTo({ top: squareTop, left: 0, behavior: 'smooth' });
    }
  };

  const currentPage = notePages.find(page => page.id === currentPageId);

  return (
    <div className='d-flex flex-center flex-column music-notation-container'>
      <div className="music-notation-controls-container">
        <button className="btn btn-notation" onClick={handlePlayPause}>
          {!isPlaying ? (
            // <img src='/assets/images/icons/play.png' alt="Play" />
            <PlayCircleOutline />
          ) : (
            // <img src='/assets/images/icons/pause.png' alt="Pause" />
            <PauseCircleOutline />
          )}
        </button>
        {/* <button className="btn btn-notation" onClick={handleStop}>
          <RefreshOutlined />
        </button> */}
      </div>
      <div ref={containerRef} style={{ position: 'relative', display: "flex", justifyContent: "center", maxWidth: "100%", maxHeight: "100%" }}>
        <img
          ref={imgRef}
          src={currentPage?.image}
          alt="Music Notation"
          style={{ maxWidth: "100%", maxHeight: "100%", width: 800, height: 1031}}
        />
        {currentPage?.squares.map((square) => {
          const scaleX = imgRef.current ? imgRef.current.clientWidth / originalWidth : 1;
          const scaleY = imgRef.current ? imgRef.current.clientHeight / originalHeight : 1;

          const scaledLeft = square.left * scaleX;
          const scaledTop = square.top * scaleY;
          const scaledWidth = square.width * scaleX;
          const scaledHeight = square.height * scaleY;

          const isActive = square.id === clickedSquareId || square.id === currentSquareId || square.id === hoveredSquareId;

          return (
            <div
              key={square.id}
              onClick={() => handleClick(square)}
              onMouseEnter={() => handleMouseEnter(square)}
              onMouseLeave={handleMouseLeave}
              style={{
                position: 'absolute',
                left: `${scaledLeft}px`,
                top: `${scaledTop}px`,
                width: `${scaledWidth}px`,
                height: `${scaledHeight}px`,
                backgroundColor: isActive ? 'rgba(4, 67, 255, 0.16)' : 'transparent',
                border: isActive ? '1px solid rgba(4, 67, 255, 0.5)' : 'none',
                cursor: 'pointer',
              }}
            />
          );
        })}
      </div>
      <div className="audio-controls-container">
        <div className="container d-flex flex-center">
          <audio
            ref={audioRef}
            src={audioSrc}
            onTimeUpdate={syncHighlight}
            style={{ width: '100%' }}
            controls
          />
        </div>
      </div>
    </div>
  );
};

export default MusicNotationImage;
