import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DragHandle } from '@mui/icons-material';
import { CourseHttpService } from 'services/course';
import NotationForm from './NotationForm';
import CustomModal from 'view/components/modals/CustomModal';
import DeleteDialog from 'view/cms/components/shared/DeleteDialog';
import SortModal from 'view/cms/components/Widgets/shared/sort/SortModal';
import { Utils } from 'utils';
import SearchQuery from 'view/cms/components/forms/shared/SearchQuery';

function NotationList({ }) {

  var navigate = useNavigate()
  const [data, setData] = useState(null);
  const [count, setCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [sortModalIsOpen, setSortModalIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);


  const location = useLocation();

  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  }

  const queryParams = getQueryParams(location.search);
  var scale = queryParams.get('scale');
  var level = queryParams.get('level');
  var artist = queryParams.get('artist');
  var transcriber = queryParams.get('transcriber');
  var time_signature = queryParams.get('time_signature');
  
  useEffect(() => {

    _getData()

    return () => {
    }
  }, [searchQuery,pageNumber])

  const _getData = () => {
    setLoading(true);
    let finalQuery = `?name=${searchQuery}`;

    if (scale) {
      finalQuery += `&scale=${scale}`
    }

    if (level) {
      finalQuery += `&level=${level}`
    }

    if (artist) {
      finalQuery += `&artists=${artist}`
    }

    if (transcriber) {
      finalQuery += `&transcriber=${transcriber}`
    }

    if (time_signature) {
      finalQuery += `&time_signature=${time_signature}`
    }


    CourseHttpService.searchNotations(finalQuery, pageNumber).then((items) => {
      if (items.results) {
        setCount(items.count)
        setData(items.results)
        setTotalPages(Math.ceil(items.count / 30)); 
      }
    });
  }


  const _handleDelete = (e, id) => {
    setSelectedId(id);
    setRefresh(refresh + 1)
    setDeleteDialogIsOpen(true)
  }

  const _onDeleteDone = () => {
    var temp = data.filter(item => item.id !== selectedId)
    setData(temp)
    setDeleteDialogIsOpen(false);
    setRefresh(refresh + 1)
  }

  const _edit = (e, item) => {
    e.preventDefault();
    setSelectedId(item.id);
    setEditModalIsOpen(true)
    setRefresh(refresh + 1)
  }


  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });

};



  return (
    <div className='box'>
      <div className="box-header mb-5">
        <h5>Notations</h5>
        <div className="btns box-header-btns d-flex align-items-center">
          Count : {count}
          <SearchQuery onQuery={(query) => {
            setSearchQuery(query);
          }} query={searchQuery} />
          <button className="btn btn-icon" onClick={() => { setSelectedId(null); setShowDelete(!showDelete) }}><i className="fa fa-trash "></i></button>
          <button className="btn btn-icon" title="Sort Notations" onClick={() => { setSelectedId(null); setSortModalIsOpen(true) }}><DragHandle /> </button>
          <button href='/cms/notation/add' className="btn btn-icon" onClick={() => { window.location.href = `/cms/notation/add` }} ><i className="fa fa-plus "></i></button>
          {/* onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }} */}

        </div>
      </div>
      <div className="box-body general-list">
        {/* {data?.map(item => (
          <div key={item.id + "cmp"} className='general-list-item' onClick={(e) => { navigate(`/cms/notation/${item.id}`) }}>
            <img className="img-fluid mb-4" src={Utils.getImage(item)} alt=""></img>
            <div className="caption">
              <h3> {Utils.getDefaultName(item)}</h3>
              <div className="btns">
                
                {showDelete && (
                  <button className="btn" onClick={(e) => { e.stopPropagation(); _handleDelete(e, item.id) }}>
                    <i className="fa fa-trash "></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        )
        )} */}
        <table className="table table-striped">
          <thead>
            <tr>
              <th>
                #
              </th>
              <th>Title</th>
              {/* <th>Writer</th>
                            <th>Artist</th> */}
              <th>Level
                {
                  level && (<span className='ml-2'><a href="/cms/notation/">X</a></span>)
                }
              </th>
              <th>Scale
                {
                  scale && (<span className='ml-2'><a href="/cms/notation/">X</a></span>)
                }

              </th>
              <th>Time Signature

                {
                  time_signature && (<span className='ml-2'><a href="/cms/notation/">X</a></span>)
                }
              </th>
              <th>Artist
                {
                  artist && (<span className='ml-2'><a href="/cms/notation/">X</a></span>)
                }
              </th>
              <th>Transcriber
                {
                  transcriber && (<span className='ml-2'><a href="/cms/notation/">X</a></span>)
                }
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data && data.map(item => (
              <tr key={"cat" + item.id}>
                <td>{item.id}</td>
                {/* <td><img src={item.image} style={{width:"100px"}} /></td> */}
                <td className='kfont'><Link to={`/cms/notation/${item.id}`}>{Utils.getDefaultName(item)}</Link></td>
                {/* <td>{item.writer && Utils.getDefaultName(item.writer)}</td> */}
                {/* <td>{item.artist && Utils.getDefaultName(item.artist)}</td> */}
                <td className='kfont'><a href={`/cms/notation/?level=${item.level && item.level.id}`}>{item.level && Utils.getDefaultName(item.level)}</a></td>
                <td className='kfont'><a href={`/cms/notation/?scale=${item.scale && item.scale.id}`}>{item.scale && Utils.getDefaultName(item.scale)}</a></td>
                <td className='kfont'><a href={`/cms/notation/?time_signature=${item.time_signature && item.time_signature.id}`}>{item.time_signature && Utils.getDefaultName(item.time_signature)}</a></td>
                <td className='kfont'><a href={`/cms/notation/?artist=${(item.artists && item.artists.length > 0) && item.artists[0].id}`}>{(item.artists && item.artists.length > 0) && Utils.getDefaultName(item.artists[0])}</a></td>
                <td className='kfont'><a href={`/cms/notation/?transcriber=${(item.transcribers && item.transcribers.length > 0) && item.transcribers[0].id}`}>{(item.transcribers && item.transcribers.length > 0) && Utils.getDefaultName(item.transcribers[0])}</a></td>
                <td className='text-right'>
                  <a href="#" onClick={(e) => { _handleDelete(e, item.id) }} className="btn btn-outline btn-swap-1">
                    <span><i className="fas fa-trash color-gray "></i></span>
                    <span>Delete <i className="fas fa-trash ms-2 color-gray "></i></span>
                  </a>

                </td>

              </tr>
            ))}


          </tbody>


        </table>

        <ul className="pagination float-end">
          <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(pageNumber - 1)} disabled={pageNumber === 1}>
              <i className="fas fa-angle-left"></i>
            </button>
          </li>
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index} className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                {index + 1}
              </button>
            </li>
          ))}
          <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(pageNumber + 1)} disabled={pageNumber === totalPages}>
              <i className="fas fa-angle-right"></i>
            </button>
          </li>
        </ul>
      </div>

      {/* <CustomModal big key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
        open={editModalIsOpen}  >

        <NotationForm onEditDone={() => { _getData(); setEditModalIsOpen(false) }} />

      </CustomModal> */}

      <DeleteDialog
        url={`/academy/course/notation/${selectedId}`}
        isOpen={deleteDialogIsOpen}
        key={"mdl" + deleteDialogIsOpen + selectedId}
        setOpen={setDeleteDialogIsOpen}
        notifyDone={_onDeleteDone}
      />

      <SortModal type="notation" data={data} setData={setData} isOpen={sortModalIsOpen} setIsOpen={setSortModalIsOpen} />

    </div>
  )
}

export default NotationList